import * as React from "react";
import {
  createContext,
  useContext,
  useState,
} from "react";
import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously , onAuthStateChanged , signInWithCustomToken , signInWithPopup , signOut} from "firebase/auth";
import { Toaster } from "react-hot-toast";
import { getFunctions , httpsCallable } from 'firebase/functions';
import { getGeo } from "./help";
import { doc , getDoc , getFirestore , setDoc , onSnapshot , increment , updateDoc , getDocs , collection as firebaseColleciton} from 'firebase/firestore';
import { GoogleAuthProvider } from "firebase/auth";
import PopupProgress from "./sso/component/popupProgress/index"
import { getHtml , rewardcollection , rewardddd  } from "./core";

const SuperfanContext = createContext();
export function SuperfanProvider({ ...props }) {
  const { firebaseConfig, collection } = props;
  const firebaseApp = initializeApp(firebaseConfig);
  const functions = getFunctions(firebaseApp);
  const auth = getAuth();
  const db = getFirestore(firebaseApp);
  const provider = new GoogleAuthProvider();
  const [ user , setUser ] = useState(null);
  const [ userMetaData , setUserMetaData ] = useState(null);

  const signIn = async () => {
    let type = localStorage.getItem("emailOrMobile").includes("@") ? "email" : "phone";
    const data = {
      type: type,
      measure: localStorage.getItem("emailOrMobile"),
      anonymousID: user.uid,
      collection: collection,
    };

    const _signIn = httpsCallable(functions, "signin");
    const response = await _signIn(data);

    let token = response.data?.token;
    if(token){
      signInWithCustomToken(auth, token);
    }
  };

  const twillioOTP = async () => {
    let type = localStorage.getItem("emailOrMobile").includes("@") ? "email" : "phone";
    return new Promise(async(resolve , reject)=>{
      try{
        switch(type){
          case "phone":
            try{
              let verifywhatsapp = httpsCallable(functions, "verifywhatsapp");
              let res1 = await verifywhatsapp({ type: type, phone: localStorage.getItem("emailOrMobile") });
              resolve(res1);
            }
            catch(e){
              reject("Invalid Phone Number");
            }
            break;
          case "email":
            try{
              let verifyEmail = httpsCallable(functions, "verifyemail");
              let res = await verifyEmail({ type: type, email: localStorage.getItem("emailOrMobile") });
              resolve(res);
            }
            catch(e){
              reject("Invalid Email");
            }
            break;
        }
      }
      catch(e){
        reject(e);
      }
    });
  };

  const checkOTP = async ({
    measure , 
    code
  }) => {
    let checkOtppp = httpsCallable(functions, "checkotp");
    let res = await checkOtppp({ measure: measure, code: code });
    return res;
  }

  const sendEmailMgs = async({
    to,
    subject,
    text,
    html
  })=>{
    let sendEdm = httpsCallable(functions, "sendedm");
    await sendEdm({
      to,
      subject,
      text,
      html
    });
  }

  const addInfo = async (info) => {
    if(!user) return;
    setDoc(doc(db, "users", user?.uid), info, {
      merge: true,
    });
  }

  const checkLeftInfo = async(uid) => {
    if(!uid) return;
    let getLeftInfo = httpsCallable(functions, "getleftInfo");
    const snapshot = await getLeftInfo({ uid: uid });
    
    if(snapshot?.status !== "error"){
      const { name , email , phoneNumber , katespadequizterm } = snapshot.data;
      if(!name || !email || !phoneNumber || !katespadequizterm){
        if(!email){
          window.setIsMobile(false);
        }

        if(!phoneNumber){
          window.setIsMobile(true);
        }
        return {
          res : true,
        };
      }
      else{
        return {
          res : false,
          data : {
            name,
            email,
            phoneNumberIsverified : phoneNumber,
            phoneNumber : phoneNumber,
            katespadequizterm : katespadequizterm
          }
        };
      }
    }
    return {
      res : true
    };
  }

  const getLeftInfo = async ({uid}) => {
    let _getLeftInfo = httpsCallable(functions, "getleftInfo");
    const snapshot = await _getLeftInfo({ uid: uid });
    return snapshot.data;
  }

  const googleSignIn = async (nav) => {
    try{
      let res = await signInWithPopup(auth, provider);
      let uid = res.user.uid;
      if(uid){
        let userRef = doc(db, "users", uid);
        setDoc(userRef, {
          email : res.user.email,
        }, { merge: true });
        nav("/route_map");
        window.toRouteMap();
      }
    }
    catch(e){
      return "error"
    }
  }

  async function recordEvent(key) {
    let _key = key.replace(/ /g, "_").toLowerCase();
    console.log("key", _key);
    let userDocRef = doc(db, collection, user.uid);
    await updateDoc(userDocRef, {
      [`events.${key}.time`]: new Date().toJSON(),
      [`events.${key}.count`]: increment(1),
    });
    return true;
  }

  const twillioOTPSMS_EMAIL = async () => {
    let type = localStorage.getItem("emailOrMobile").includes("@") ? "email" : "phone";
    return new Promise(async(resolve , reject)=>{
      try{
        switch(type){
          case "phone":
            try{
              let verifywhatsapp = httpsCallable(functions, "verifysms");
              let res1 = await verifywhatsapp({ type: type, phone: localStorage.getItem("emailOrMobile") });
              resolve(res1);
            }
            catch(e){
              reject("Invalid Phone Number");
            }
            break;
          case "email":
            try{
              let verifyEmail = httpsCallable(functions, "verifyemail");
              let res = await verifyEmail({ type: type, email: localStorage.getItem("emailOrMobile") });
              resolve(res);
            }
            catch(e){
              reject("Invalid Email");
            }
            break;
        }
      }
      catch(e){
        reject(e);
      }
    });
  };

  const sendWhatsapp = async ({
    a,b,c,d,phone
  }) => {
    let _sendwhatsapp = httpsCallable(functions, "sendwhatsapp");
    let res = await _sendwhatsapp({
      a : a,
      b : b,
      c : c,
      d : d,
      phone: phone
    });
    return res;
  }

  const settt = async(email , phone , iiid)=>{
    if(userMetaData === null) return;

    let _json = {
      uid: user.uid,
      reward: rewardddd,
      generatedTime: new Date().getTime(),
      collection: collection,
    }

    const { isSended } = userMetaData;
    let url = window.location.origin + "/redeem?tokenid=" + iiid;


    setDoc(doc(db, rewardcollection, iiid), _json , { merge: true });  
    if(!isSended){
      let html = getHtml("Singapore" , "Singapore" , "ION Orchard Level 1 Atrium" , "28 August – 11 September 2024" , url);
      setDoc(doc(db, collection, user.uid), {
        isSended: true,
      }, { merge: true });

      sendEmailMgs({
        to: email,
        subject: "Here is your complimentary popsicle",
        text: `Congratulations! You got your popsicle.`,
        html: html
      })


      sendWhatsapp({
        a : "Singapore",
        b : "ION Orchard Level 1 Atrium",
        c : "28 August – 11 September 2024",
        d : url,
        phone: phone
      });
    }

    setTimeout(() => {
      window.location.href = url;
    }, 10000);
  }

  const signInWithUid = async ({token}) => {
    let docRef = doc(db, rewardcollection, token);
    const snapshot = await getDoc(docRef);
    if(snapshot.exists()){
      let { uid } = snapshot.data();
      console.log("uid", uid);
      let _getCustomToken = httpsCallable(functions, "getcustomtoken");
      let res = await _getCustomToken({ uid: uid });
      let token = res.data;
      signInWithCustomToken(auth, token);  
    }
    else{
      alert("Invalid Token");
      window.location.href = "/";
    }
  }

  const init = async()=>{
    if(!user) return;
    const _docRef = doc(db, collection, user.uid);
    const snapshot = await getDoc(_docRef);
    
    return new Promise(async(resolve , reject)=>{
      if(snapshot.exists()){
        setDoc(_docRef, {
          replay : increment(1)
        }, {merge:true});

        setInterval(()=>{
          setDoc(_docRef, {
            timespent: increment(5)
          },{merge:true});
        },5000);

      }
      else{
        const [brand , region , campaign] = collection.split("-");
        let geoData = await getGeo();
        const details = {
          brand,
          region,
          campaign,
          user: user?.uid,
          createTime: new Date().toJSON(),
          redeemTime: null,
          updateTime: null, // for bigquery
          userAgent: navigator.userAgent,
          replay: 0,
          timespent: 0,
          score: 0,
          events: {},
          dataCollection: null,
        }
        setDoc(_docRef, {
          ...details,
          ...geoData
        },{merge:true});

        setInterval(()=>{
          setDoc(_docRef, {
            timespent: increment(5)
          },{merge:true});
        },5000);

      }
    });
  }

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setUserMetaData(null);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const getAllUsers = async () => {
    const snapshot = await getDocs(firebaseColleciton(db, "users"));
    let arr = [];
    snapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    console.log(arr);
  }

  React.useEffect(() => {
    window.getAllUsers = ()=>{getAllUsers()};
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        signInAnonymously(auth , (_user) => {
          setUser(_user);
        });  
      }
    });

    window.logout = ()=>{
      logout()
    };
  }, []);

  React.useEffect(()=>{
    if(user){
      init()

      let docRef = doc(db , collection , user.uid);
      const unsubscribe = onSnapshot(docRef, (doc) => {
        if(doc.exists()){
          setUserMetaData(doc.data());
        }
      });
    }
  },[user]);

  return (
    <SuperfanContext.Provider   
      value={{
        signIn,
        twillioOTP,
        twillioOTPSMS_EMAIL,
        addInfo,
        checkLeftInfo,
        googleSignIn,
        sendEmailMgs,
        checkOTP,
        sendWhatsapp,
        user,
        db,
        collection,
        settt,
        userMetaData,
        signInWithUid,
        recordEvent,
        functions,
        getLeftInfo
      }}
    >
      {props.children}
      <Toaster/>
      <PopupProgress/> 
    </SuperfanContext.Provider>
  );
}



export const useSuperfan = () => useContext(SuperfanContext);