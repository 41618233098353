import React, { useEffect, useRef } from 'react';
// import '@google/model-viewer';
import { useSuperfan } from '../superfancontext';
const Ar = ({ src, alt, style , id}) => {
  const modelViewerRef = useRef(null);
  const { recordEvent } = useSuperfan();
  return (
    <model-viewer
      ref={modelViewerRef}
      src={src}
      alt={alt}
      ar
      ar-modes="scene-viewer quick-look webxr"
      camera-controls
      auto-rotate
      style={style}
    >
       <button slot="ar-button"
        id={id}
        onClick={() => {
          recordEvent(id);
        }}
      >
      </button>
    </model-viewer>
  );
};

export default Ar;