import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { SuperfanProvider } from '../src/superfancontext/index';

const firebaseConfig = {
    apiKey: "AIzaSyCvOLqEkE3S0K6NKMW6vIO5MjVdxJ4k0Zw",
    authDomain: "superfan-3a794.firebaseapp.com",
    databaseURL: "https://superfan-3a794-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "superfan-3a794",
    storageBucket: "superfan-3a794.appspot.com",
    messagingSenderId: "18328979437",
    appId: "1:18328979437:web:b6fb5190d1b2ba9445c9e4",
    measurementId: "G-JVGTV6ZQEK"
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <SuperfanProvider
            collection="hermes-my-chez"
            firebaseConfig={firebaseConfig}
        >
            <App />
        </SuperfanProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
