
import { useEffect , useState} from "react";
import Gcontainer from "./gcontainer";
import animationData from "./Expotr.json";
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import React from "react";
import { delayTime } from "../../core"
const cloudImages = [
    '/2d/cloud1.png',
    '/2d/cloud2.png',
    '/2d/cloud3.png',
  ];
const generateList = (quantity = 6)=>{
    let _ = {};
    for(let i = 0; i < quantity ; i ++){
        let _id = parseInt(Date.now() * Math.random());
        _[_id] = {
            x : window.innerWidth * Math.random(),
            y : window.innerHeight * Math.random() + window.innerHeight * 0.25,
            image: cloudImages[Math.floor(Math.random() * cloudImages.length)], 
            speedX : Math.round(1 * Math.random() + 0.5)
        }
    }

    return _;
}

const list = {
    "759151482339": {
        "x": 173.43964741463557,
        "y": 218.03555152848907,
        "image": "/2d/cloud2.png",
        "speedX": 1
    },
    "1006981330261": {
        "x": 206.01466495688413,
        "y": 497.32603603697305,
        "image": "/2d/cloud1.png",
        "speedX": 1
    },
    "204829861786": {
        "x": 10.975453979804788,
        "y": 705.1995879507435,
        "image": "/2d/cloud1.png",
        "speedX": 1
    },
    "1463356482503": {
        "x": 24.739125240101274,
        "y": 387.554222360782,
        "image": "/2d/cloud1.png",
        "speedX": 1
    },
    "42496766102": {
        "x": 321.20872378991,
        "y": 296.3761989990383,
        "image": "/2d/cloud2.png",
        "speedX": 1
    },
    "1331620269224": {
        "x": 117.43317100765513,
        "y": 772.1172813879382,
        "image": "/2d/cloud2.png",
        "speedX": 1
    }
}

const Index = ()=>{
    const [play , setPlay] = useState(true);
    let imageWidth = window.innerWidth * 0.4;
    useEffect(()=>{
        const ani = ()=>{
            Object.keys(list).map((itemID , index)=>{
                let item = document.getElementById(itemID);
                if(item){
                    let x = item.getAttribute("x");
                    x = parseInt(x);
                    x += list[itemID]?.speedX || 1;
                    if(x > window.innerWidth){
                        x = -300
                    }
                    item.setAttribute("x" , x)    
                }
            })
            requestAnimationFrame(ani);
        }

        window.setPlay1 = ()=>{
            setPlay((prev)=>{
                if(!prev){
                    document.getElementById("ani2").style.opacity = 0;
                    document.getElementById("ani3").style.opacity = 0;    
                }
                else{
                    setTimeout(()=>{
                        document.getElementById("ani2").style.opacity = 1;
                        document.getElementById("ani3").style.opacity = 1;    
                    },delayTime);
                }
                return !prev;
            });
        }

        ani();
    },[])


    return (
        <>
            <Gcontainer
                play = {!play}
                setPlay = {setPlay}
                imageWidth = {imageWidth}
                id={"ani1"}
                imgSrc={"/1.png"}
                backgroundColor={"#f7b2a7"}
                invert = {true}
                y = {-window.innerHeight * 0.21}
            >
                {
                    Object.keys(list).map((item , index)=>{
                        return (
                            <image 
                                clipPath={"url(#clipani1)"}
                                height="36" width="117" href={list[item]?.image} key={item} x={list[item].x} y ={list[item].y} id={item}/>
                        )
                    })
                }
                
                {/* add html button */}
                <image
                    href="/2d/logo.png"
                    width={window.innerHeight * 0.09 * 1.72}
                    height={window.innerHeight * 0.09}
                    clipPath={"url(#clipani1)"}
                    x={window.innerWidth * 0.5 - window.innerHeight * 0.09 * 1.72 / 2}
                    y={window.innerHeight * 0.0822 - window.innerHeight * 0.09 / 2 + window.innerHeight * 0.21}
                />

                <image
                    href="/2d/word1.png"
                    height={window.innerHeight * 0.1}
                    width={window.innerHeight * 0.1 * 2.76}
                    clipPath={"url(#clipani1)"}
                    style={{opacity : 1 , transition : "opacity 1s"}}
                    id="word1"
                    x={window.innerWidth * 0.5 - window.innerHeight * 0.1 * 2.76 / 2}
                    y={window.innerHeight * 0.25 - window.innerHeight * 0.1 / 2 + window.innerHeight * 0.21}
                />


            </Gcontainer>            
        </>
    )
}

export default Index;