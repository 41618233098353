
import {motion} from "framer-motion";
import { useEffect , useState} from "react";
import Gcontainer from "./gcontainer";
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { delayTime } from "../../core"
import React from "react";

const list = {
    "75915148223393": {
        "x": 173.43964741463557,
        "y": 218.03555152848907,
        "image": "/2d/cloud2.png",
        "speedX": 1
    },
    "10069813302361": {
        "x": 206.01466495688413,
        "y": 497.32603603697305,
        "image": "/2d/cloud1.png",
        "speedX": 1
    },
    "204829863211786": {
        "x": 10.975453979804788,
        "y": 705.1995879507435,
        "image": "/2d/cloud1.png",
        "speedX": 1
    },
    "1463356483452503": {
        "x": 24.739125240101274,
        "y": 387.554222360782,
        "image": "/2d/cloud1.png",
        "speedX": 1
    },
    "42496766dfg102": {
        "x": 321.20872378991,
        "y": 296.3761989990383,
        "image": "/2d/cloud2.png",
        "speedX": 1
    },
    "133162026sdf9224": {
        "x": 117.43317100765513,
        "y": 772.1172813879382,
        "image": "/2d/cloud2.png",
        "speedX": 1
    }
}

const Index = ()=>{

    const [play , setPlay] = useState(false);


    useEffect(()=>{
        const ani = ()=>{
            Object.keys(list).map((itemID , index)=>{
                let item = document.getElementById(itemID);
                if(item){
                    let x = item.getAttribute("x");
                    x = parseInt(x);
                    x += list[itemID]?.speedX || 1;
                    if(x > window.innerWidth){
                        x = -300
                    }
                    item.setAttribute("x" , x)    
                }
            })
            requestAnimationFrame(ani);
        }

        window.setPlay2 = ()=>{
            setPlay((prev)=>{
                if(!prev){
                    document.getElementById("ani1").style.opacity = 0;
                    document.getElementById("ani3").style.opacity = 0;    
                }
                else{
                    setTimeout(()=>{
                        document.getElementById("ani1").style.opacity = 1;
                        document.getElementById("ani3").style.opacity = 1;    
                    },delayTime);
                }
                return !prev;
            });
        }

        ani();
    },[])

    let buttonHeight = 50;
    let buttonWidth = 180;


    return (
        <>
            <Gcontainer
                play={!play}
                setPlay={setPlay}
                imageWidth={window.innerWidth * 0.4}
                y={window.innerHeight * 0.05}
                id={"ani2"}
                imgSrc={"/2.png"}
                backgroundColor={"#fbef9d"}
            >
                {
                    Object.keys(list).map((item , index)=>{
                        return (
                            <image 
                                clipPath={"url(#clipani2)"}
                                height="36" width="117" href={list[item]?.image} key={item} x={list[item].x} y ={list[item].y - window.innerHeight * 0.05} id={item}/>
                        )
                    })
                }

                  <image
                    href="/2d/logo_blue.png"
                    width={window.innerHeight * 0.09 * 1.72}
                    height={window.innerHeight * 0.09}
                    clipPath={"url(#clipani2)"}
                    x={window.innerWidth * 0.5 - window.innerHeight * 0.09 * 1.72 / 2 }
                    y={window.innerHeight * 0.0822 - window.innerHeight * 0.09 / 2 - window.innerHeight * 0.05}
                />
            </Gcontainer>
        </>
    )
}

export default Index;