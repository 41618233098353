import './App.css';
import React, { useEffect } from "react";
import AR from "./components/ar";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import SvgAnimation from "./effects/svg";
import { useSuperfan } from "./superfancontext";
const App = () => {
    return ( 
        <>
            <SvgAnimation/>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/ios" element={<IOS />} />
                <Route path="/android" element={<Android />} />
            </Routes>

            <button style={{
                width : "11rem",
                height : "3rem",
                border : "1px solid #fad3d0",
                color : "#f07d48",
                backgroundColor : "white",
                fontSize : "1rem",
                cursor : "pointer",
                borderRadius : "3rem",
                zIndex : "1000",
                position : "absolute",
                bottom : "15%",
                left : "50%",
                transform : "translateX(-50%)",
                pointerEvents : "none",
                opacity : "0",
                transition : "opacity 0.5s"
            }}
            onClick={()=>{
                const type = window?.sellectedItem;

                switch (type) {
                    case "item1":
                        window.item1.func1()
                        break;
                    case "item2":
                        window.item2.func1()
                        break;
                    case "item3":
                        window.item3.func1()
                        break;
                }
            }}
            id='buttonHander'
            >BACK</button>

            <LegerLine/>
        </>
    );
};

export default App;

const IOS = () => {
    const { recordEvent } = useSuperfan();

    return (
        <div
            style={{
                position : "absolute",
                opacity : "0",
                pointerEvents : "none"
            }}
        >
            <div>
                <div
                    style={{
                        width : "10rem",
                        margin : "auto"
                    }}
                >
                    <a
                        rel='ar'
                        href='/3d/CHEZ_Purple_A.usdz'
                        id='ar-link-a'

                        onClick={() => {recordEvent('ar-link-a')}}
                    >
                        <img style={{width : "10rem" , height : "10rem"}} class="image-model" src="/2d/purpleA.png" alt="" data-hires-status="replaced"></img>
                        <div class="block-link" style={{textAlign : "center"}}>A</div>
                    </a>
                </div>

                <br/>

                <div
                    style={{
                        width : "10rem",
                        margin : "auto"
                    }}
                >
                    <a
                        rel='ar'
                        href='/3d/CHEZ_Purple_B.usdz'
                        id='ar-link-b'
                        onClick={() => {recordEvent('ar-link-b')}}
                    >
                        <img style={{ height : "10rem"}} class="image-model" src="/2d/purple-b.png" alt="" data-hires-status="replaced"></img>
                        <div class="block-link" style={{textAlign : "center"}}>B</div>
                    </a>
                </div>

                <br/>

                <div
                    style={{
                        width : "10rem",
                        margin : "auto"
                    }}
                >
                    <a
                        rel='ar'
                        href='/3d/CHEZ_Purple_C.usdz'
                        id='ar-link-c'
                        onClick={() => {recordEvent('ar-link-c')}}
                    >
                        <img style={{height : "10rem"}} class="image-model" src="/2d/purple-c.png" alt="" data-hires-status="replaced"></img>
                        <div class="block-link" style={{textAlign : "center"}}>C</div>
                    </a>
                </div>
            </div>
        </div>
    )
};

const Android = () => {
    return (
      <>
            <AR
                src={"/3d/CHEZ_Purple_A.glb"}
                alt={"A 3D model of a cloche dish"}
                style={{width : "10rem" , height : "10rem"}}
                id='ar-link-a'
                />

            <AR
                src={"/3d/CHEZ_Purple_B.glb"}
                alt={"A 3D model of a cloche dish"}
                style={{width : "10rem" , height : "10rem"}}
                id='ar-link-b'
                />

            <AR
                src={"/3d/CHEZ_Purple_C.glb"}
                alt={"A 3D model of a cloche dish"}
                style={{width : "10rem" , height : "10rem"}}
                id='ar-link-c'
                />
      </>
    )
}

const Home = () => {
    const navigate = useNavigate();
    // check device
    useEffect(() => {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        if (isIOS) {
            navigate("/ios");
        } else {
            navigate("/android");
        }
    }, []);
    return  null;
}

export const LegerLine = ({ currentPage }) => {
    return (
      <div
        className="footer"
        style={{
          position: "absolute",
          bottom: "1.5%",
          left: "50%",
          zIndex: 10000000000000,
          transform: "translate(-50%,0)",
          width: "100vw",
          textAlign: "center",
          opacity: 1,
          zIndex: 10000,
        }}
      >
        <span
          className="mt-auto"
          style={{
            fontSize: "0.5rem",
            color: "white",
            fontFamily: "HelveticaLTPro-Roman",
          }}
        >
          <span
            style={{
              fontSize: "0.65rem",
              position: "relative",
              top: "-0.03svh",
              marginRight: "0.5vw",
            }}
          >
            &reg;
          </span>
          {"  "} ALL RIGHTS RESERVED BY HERMES. POWERED BY{" "}
          <a
            style={{
              color: "white",
              fontSize: "0.51rem",
              fontFamily: "HelveticaLTPro-Roman",
              textDecoration: "underline",
            }}
            href="https://www.instagram.com/conten.tech/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            CONTEN.T
          </a>
        </span>
      </div>
    );
  };
  