import Phaser from "phaser";
import { createItem } from "./helper";
import { delayTime } from "../core";

class Helper extends Phaser.Scene {
    constructor() {
        super("landing");
        this.width = window.innerWidth * window.devicePixelRatio;
        this.height = window.innerHeight * window.devicePixelRatio
    }

    createItem(props) {
        const { name , y , callBack = ()=>{}} = props;
        let item1 = this.add.sprite(0, 0, name);
        let itemWidth = this.height * 0.3;
        let initHeight = y;
        item1.setPosition(this.width / 2, initHeight);
        item1.setDisplaySize(itemWidth, itemWidth);
        item1.playItem1forward = () => {
            window.animating = true;

            let ratio = itemWidth * 1.6 / item1.width;
            this.tweens.add({
                targets: item1,
                y : this.height * 0.45,
                scale : ratio,
                duration: 500,
                delay: 200,
                ease: "Power2",
                onComplete : ()=>{
                    window.animating = false;
                }
            });
            item1.play(name + "-forward");
        }

        item1.playItem1backward = () => {
            window.animating = true;
            let ratio = itemWidth/ item1.width;
            this.tweens.add({
                targets: item1,
                y : initHeight,
                scale : ratio,
                duration: 500,
                ease: "Power2",
                onComplete : ()=>{
                    window.animating = false;
                }
            });
            item1.play(name + "-backward");
        }


        item1.func1 = () => {
            if(window?.animating){return;}
            let buttonHander = document.getElementById("buttonHander");
            if (item1.anims?.currentAnim?.key === name + "-forward") {
                item1.playItem1backward();
                callBack(true);
                buttonHander.style.opacity = 0;
                buttonHander.style.pointerEvents = "none";
            } else {
                item1.playItem1forward();
                buttonHander.style.opacity = 1;
                buttonHander.style.pointerEvents = "auto";
                callBack(false);
            }
        };

        item1.setInteractive();
        item1.on("pointerdown", () => {
            item1.func1();
        });

        return item1;
    }

    createForItem1(props) {
        const { name , y , callBack = ()=>{}} = props;
        let item1 = this.add.sprite(0, 0, name);
        let itemWidth = this.height * 0.65;
        let initHeight = y;
        item1.setPosition(this.width / 2, initHeight);
        item1.setDisplaySize(itemWidth, itemWidth);
        item1.play("idea")
        item1.playItem1forward = () => {
            window.animating = true;
            let ratio = itemWidth * 0.7 / item1.width;
            this.tweens.add({
                targets: item1,
                y : this.height * 0.45,
                scale : ratio,
                duration: 500,
                delay: 200,
                ease: "Power2",
                onComplete : ()=>{
                    window.animating = false;
                }
            });
            item1.play(name + "-forward");
        }

        item1.playItem0forward = () => {
            window.animating = true;
            let ratio = itemWidth * 0.8 / item1.width;
            let word1 = document.getElementById("word1");
            word1.style.opacity = 0;

            // theme-color
            this.tweens.add({
                targets: item1,
                y : this.height * 0.5,
                scale : ratio,
                duration: 500,
                delay: 200,
                ease: "Power2",
                onComplete : ()=>{
                    item1.play(name + "-forward");
                    this.time.delayedCall(2000, ()=>{
                        window.animating = false;
                        let metaThemeColor = document.querySelector("meta[name=theme-color]");
                        metaThemeColor.setAttribute("content", "#cee7df");            
                    }, [], this);
                }
            });
        }

        item1.playItem1backward = () => {
            window.animating = true;
            let ratio = itemWidth * 0.46 / item1.width;
            this.tweens.add({
                targets: item1,
                y : this.height * 0.23,
                scale : ratio,
                duration: 500,
                ease: "Power2",
                onComplete : ()=>{
                    window.animating = false;
                }
            });
            item1.play(name + "-backward");
        }
        
        item1.func0 = () => {
            if(window.animating){return;}
            item1.playItem0forward();
            
            this.time.delayedCall(2000, () => {
                item1.playItem1backward();
                callBack(true);
                item1.landingEnd = true;
            }, [], this);
        }

        item1.func1 = () => {
            let buttonHander = document.getElementById("buttonHander");

            if(window?.animating){return;}
            if (item1.anims?.currentAnim?.key === name + "-forward") {
                item1.playItem1backward();
                callBack(true);
                buttonHander.style.opacity = 0;
                buttonHander.style.pointerEvents = "none";
            } else {
                item1.playItem1forward();
                buttonHander.style.opacity = 1;
                buttonHander.style.pointerEvents = "auto";
                callBack(false);
            }
        };

        item1.setInteractive();
        item1.on("pointerdown", (event) => {
            if(item1?.landingEnd){
                item1.func1();
            }
            else{
                item1.startPos = {
                    x : event.x,
                    y : event.y
                }
            }
        });

        item1.on("pointerup", (event) => {
            if(!item1?.landingEnd){
                let length = Math.sqrt(Math.pow(event.x - item1.startPos.x, 2) + Math.pow(event.y - item1.startPos.y, 2));
                if(length > 50 * window.devicePixelRatio){
                    item1.func0();
                    item1.landingEnd = true;

                    this.tweens.add({
                        targets : this.dragani,
                        alpha : 0,
                        duration : 300,
                        onComplete : ()=>{
                            this.dragani.destroy();
                        }
                    });
                }
            }
        });

        return item1;
    }
}

class Landing extends Helper {
    create() {
        window.item1 = this.createForItem1({
            y : this.height * 0.4,
            name : "item1",
            invert : true,
            callBack : (is)=>{
                window.setPlay1();
                
                if(!is){
                    window.sellectedItem = "item1";
                    let metaThemeColor = document.querySelector("meta[name=theme-color]");
                    metaThemeColor.setAttribute("content", "#f7b2a7");            


                    this.tweens.add({
                        targets : [window.item2 , window.item3],
                        alpha : 0,
                        duration : 200,
                    });

                    let element = document.getElementById("ar-link-a");
                    if(element){
                        this.time.delayedCall(1500, ()=>{
                            element.click();
                        }, [], this); 
                    }

                    localStorage.setItem("item1" , "true");
                }
                else{
                    this.time.delayedCall(600, ()=>{
                        let metaThemeColor = document.querySelector("meta[name=theme-color]");
                        metaThemeColor.setAttribute("content", "#cee7df");                
                    }, [], this);        

                    this.tweens.add({
                        targets : [window.item2 , window.item3],
                        alpha : 1,
                        duration : 200,
                        delay : delayTime,
                    });   
                }
            }
        });
        window.item2 = this.createItem({
            y : this.height * 0.5,
            name : "item2",
            callBack : (is)=>{
                window.setPlay2();
                if(!is){
                    window.sellectedItem = "item2";
                    let metaThemeColor = document.querySelector("meta[name=theme-color]");
                    metaThemeColor.setAttribute("content", "#fbef9d");            

                    this.tweens.add({
                        targets : [window.item1 , window.item3],
                        alpha : 0,
                        duration : 200,
                    });
                    let element = document.getElementById("ar-link-b");
                    if(element){
                        this.time.delayedCall(1500, ()=>{
                            element.click();
                        }, [], this); 
                    }
                }
                else{
                    this.time.delayedCall(600, ()=>{
                        let metaThemeColor = document.querySelector("meta[name=theme-color]");
                        metaThemeColor.setAttribute("content", "#cee7df");                
                    }, [], this);            

                    this.tweens.add({
                        targets : [window.item1 , window.item3],
                        alpha : 1,
                        duration : 200,
                        delay : delayTime,
                    });
                }
            }
        });
        window.item3 = this.createItem({
            y : this.height * 0.75,
            name : "item3",
            callBack : (is)=>{
                window.setPlay3();
                if(!is){
                    window.sellectedItem = "item3";
                    let metaThemeColor = document.querySelector("meta[name=theme-color]");
                    metaThemeColor.setAttribute("content", "#5cb780");            

                    this.tweens.add({
                        targets : [window.item1 , window.item2],
                        alpha : 0,
                        duration : 200,
                    });
                    let element = document.getElementById("ar-link-c");
                    if(element){
                        this.time.delayedCall(1500, ()=>{
                            element.click();
                        }, [], this); 
                    }
                }
                else{
                    this.time.delayedCall(600, ()=>{
                        let metaThemeColor = document.querySelector("meta[name=theme-color]");
                        metaThemeColor.setAttribute("content", "#cee7df");                
                    }, [], this);

                    this.tweens.add({
                        targets : [window.item1 , window.item2],
                        alpha : 1,
                        duration : 200,
                        delay : delayTime,
                    });
                }
            }
        });

        window.item2.alpha = 0;
        window.item3.alpha = 0;

        let imgHeight = this.height * 0.25;
        let imgWidth = imgHeight * 3 / 5;
        this.dragani = this.add.sprite(
            this.width/2 + imgWidth * 0.36, this.height/2 + imgHeight * 0.6, "dragani");
        this.dragani.setOrigin(0.5, 0.5);
        this.dragani.setDisplaySize(imgWidth, imgHeight);
        this.dragani.play("drag");
    }
}

export default Landing;