import { useEffect } from "react";
import Ani1 from "./ani/ani1";
import Ani2 from "./ani/ani2";
import Ani3 from "./ani/ani3";
import Canvas2d from "../2d";
import React from "react";

const list = {
    "item1" : {
        x : -window.innerWidth * 0.08 - window.innerHeight * 0.05,
        y : window.innerHeight * 0.08,
        width : window.innerHeight * 0.2,
        image : "/2d/cloud1.png",
        // random between -1 and 1
        speed : Math.random() * 2 - 1,
        speedY : Math.random() * 2 - 1
    },
    "item2" : {
        x : -window.innerWidth * 0.05 - window.innerHeight * 0.06,
        y : window.innerHeight * 0.25,
        width : window.innerHeight * 0.15,
        image : "/2d/cloud2.png",
        speed : Math.random() * 2 - 1,
        speedY : Math.random() * 2 - 1

    },
    "item3" : {
        x : -window.innerWidth * 0.05 - window.innerHeight * 0.01,
        y : window.innerHeight * 0.35,
        width : window.innerHeight * 0.18,
        image : "/2d/cloud1.png",
        speed : Math.random() * 2 - 1,
        speedY : Math.random() * 2 - 1
    },
    "item4" : {
        x : -window.innerWidth * 0.05 - window.innerHeight * 0.01,
        y : window.innerHeight * 0.9,
        width : window.innerHeight * 0.2,
        image : "/2d/cloud1.png",
        speed : Math.random() * 2 - 1,
        speedY : Math.random() * 2 - 1
    },
    "item10" : {
        x : window.innerWidth * 0.01,
        y : window.innerHeight * 0.7,
        width : window.innerHeight * 0.14,
        image : "/2d/cloud2.png",
        speed : Math.random() * 2 - 1,
        speedY : Math.random() * 2 - 1
    },
    // right
    "item5" : {
        x : window.innerWidth - window.innerHeight * 0.23 + window.innerHeight * 0.08,
        y : window.innerHeight * 0.15,
        width : window.innerHeight * 0.23,
        image : "/2d/cloud2.png",
        speed : Math.random() * 2 - 1,
        speedY : Math.random() * 2 - 1
    },
    "item6" : {
        x : window.innerWidth - window.innerHeight * 0.15 + window.innerHeight * 0.05,
        y : window.innerHeight * 0.42,
        width : window.innerHeight * 0.15,
        image : "/2d/cloud1.png",
        speed : Math.random() * 2 - 1,
        speedY : Math.random() * 2 - 1
    },
    "item7" : {
        x : window.innerWidth - window.innerHeight * 0.2 + window.innerHeight * 0.08,
        y : window.innerHeight * 0.65,
        width : window.innerHeight * 0.2,
        image : "/2d/cloud1.png",
        speed : Math.random() * 2 - 1,
        speedY : Math.random() * 2 - 1
    },
    "item8" : {
        x : window.innerWidth - window.innerHeight * 0.08 - window.innerHeight * 0.01,
        y : window.innerHeight * 0.75,
        width : window.innerHeight * 0.08,
        image : "/2d/cloud1.png",
        speed : Math.random() * 2 - 1,
        speedY : Math.random() * 2 - 1
    },
    "item9" : {
        x : window.innerWidth - window.innerHeight * 0.12 - window.innerHeight * 0.01,
        y : window.innerHeight * 0.83,
        width : window.innerHeight * 0.12,
        image : "/2d/cloud1.png",
        speed : Math.random() * 2 - 1,
        speedY : Math.random() * 2 - 1
    },

}
const Index = ()=>{
    const phaserRef = React.useRef(null);

    useEffect(()=>{
        const ani = () => {
            const time = Date.now() / 1000; // Get the current time in seconds
          
            Object.keys(list).forEach((itemID, index) => {
              let item = document.getElementById(itemID);
              if (item) {          
                // Calculate new x value using a sine wave for floating effect
                const amplitude = 20; // Amplitude of the floating effect
                const amplitudeY = 10; // Amplitude of the floating effect
                const frequency = 1; // Frequency of the floating effect
                const frequencyY = 0.3; // Frequency of the floating effect
                let newX = amplitude * Math.sin(frequency * time + index) * list[itemID].speed + list[itemID].x;

                let newY = amplitudeY * Math.sin(frequencyY * time + index) * list[itemID].speedY + list[itemID].y;
                // Set the new x value
                item.setAttribute("x", newX);
                item.setAttribute("y", newY);
              }
            });
          
            requestAnimationFrame(ani);
        };          
        ani();
    },[])
    return (
        <svg
            width={window.innerWidth}
            height={window.innerHeight}
            viewBox={`0 0 ${window.innerWidth} ${window.innerHeight}`}
            style={{
                position : "absolute",
                top : "0",
                left : "0",
                zIndex : "1"
            }}
        >
            <rect
                width={window.innerWidth}
                height={window.innerHeight}
                fill="rgb(206 231 223)"
            />

            <g>
                <image
                    href="/2d/logo.png"
                    width={window.innerHeight * 0.09 * 1.72}
                    height={window.innerHeight * 0.09}
                    x={window.innerWidth * 0.5 - window.innerHeight * 0.09 * 1.72 / 2}
                    y={window.innerHeight * 0.0822 - window.innerHeight * 0.09 / 2}
                />
            </g>

            {
                Object.keys(list).map((item , index)=>{
                    return (
                        <image 
                            width={list[item]?.width || window.innerHeight * 0.09}
                            href={list[item]?.image} 
                            key={item} 
                            x={list[item].x} 
                            y ={list[item].y} 
                            id={item}
                        />
                    )
                })
            }


            <Ani2/>
            <Ani3/>
            <Ani1/>


            <foreignObject
                width={window.innerWidth}
                height={window.innerHeight}
            >
                <Canvas2d ref={phaserRef}/>
            </foreignObject>
        </svg>
    ) 
}

export default Index;