import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Button , Input , InputEmail , InputMobile} from "../../common"
import "../../common/superfans.css"
import { useSuperfan } from '../../../index';
import React ,{ useEffect, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { gsap } from 'gsap';
import { setDoc , doc , getDoc} from 'firebase/firestore';
import { ranId } from "../../../core"
import { Terms } from "./term";
import { httpsCallable } from 'firebase/functions';
const Index  = ()=> {
    const navigate = useNavigate();
    const inputRef = useRef();
    const inputRef2 = useRef();
    const { addInfo , db , functions , user , getLeftInfo} = useSuperfan();

    const [ isMobile , setIsMobile] = React.useState(false);
    const {settt } = useSuperfan();
    const [ info , setInfo ] = React.useState({
        terms : false,
    });
    useEffect(()=>{
        
        window.setIsMobile = (flag)=>{
            setIsMobile(flag);
        }
    })    
    
    
    return (
        <section
            className='superfanWraper'
            style={{
                position : "fixed",
                width : "100svw",
                height : "100svh",
                zIndex : 9999,
                overflow : "hidden",

                top : 0,
                boxSizing : "border-box",
                backgroundColor : "rgba(0,0,0,0.6)",
                pointerEvents : "none",
                opacity : 0,
                transition : "all 0.3s",
            }}
            id='collect-info2'
        >
            <motion.div
                style={{
                    width : "90svw",
                    backgroundColor : "black",
                    borderRadius : "0.5rem 0.5rem 0 0",
                    boxSizing : "border-box",
                    padding : "0 2rem",
                    position : "absolute",
                    bottom : "-100%",
                    left : "50%",
                    transform : "translateX(-50%)",
                    height : "65svh",
                    minHeight : "25rem",
                    height : "fit-content",
                }}
                id='collect-info2-child'
                onClick={(e)=>{
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >

            <div className='block' style={{height : "2.2rem"}}/>

            <p style={{textAlign : "left"}}>
                Almost there! Please enter your<br/> details to receive redemption link. 
            </p>
            <div className='block' style={{height : "1.2rem"}}/>
            {/*  */}
            <div
                style={{display : "flex" , justifyContent : "space-between"}}
            >  
                <h5
                style={{color : "white"}}
                >Name
                </h5>
            </div>

            <div className='block' style={{height : "0.5rem"}}/>

            {/*  */}
            <Input
                placeholder = {"John"}
                type='text'
                onChange={(e)=>{
                    inputRef.current = e.target.value;
                }}
            />
            {/*  */}

            <div className='block' style={{height : "0.8rem"}}/>

            <div
                style={{display : "flex" , justifyContent : "space-between"}}
            >  
                <h5
                style={{color : "white"}}
                >{isMobile ? "Mobile" : "Email"}
                </h5>
            </div>

            <div className='block' style={{height : "0.5rem"}}/>

            {/*  */}
            {/*inputRef2.current = e.target.value; */}
            { isMobile && <InputMobile valueRef={inputRef2} local = {false}/>}
            { !isMobile && <InputEmail valueRef={inputRef2} local = {false} placeholder={"john@example.com"}/>}
            
            <div className='block' style={{height : "1.5rem"}}/>

            <Terms terms = {info.terms} setInfo = {setInfo}/>
            
            <div className='block' style={{height : "2.2rem"}}/>

            <Button 
                id = "2"
                style = {{
                    backgroundColor: "#00bf63",
                    color: "#000",
                }}

                name = "Enter superfan"
                onClick = {async()=>{
                    // check format mobile and email
                    const mobileRegex = /^\+[0-9]{1,3}\s?[0-9]{1,14}$/;
                    let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

                    if(!info.terms){
                        toast.error("Please accept terms and conditions");
                        return;
                    }
                    
                    if(isMobile){
                        if(!mobileRegex.test(inputRef2.current)){
                            toast.error("Invalid Mobile Number");
                            return;
                        }
                    }
                    else{
                        if(!emailRegex.test(inputRef2.current)){
                            toast.error("Invalid Email");
                            return;
                        }    
                    }
                    if(inputRef.current?.length === 0){
                        toast.error("Name is required");
                        return;
                    }

                    if(isMobile){
                        addInfo({
                            name : inputRef.current,
                            phoneNumber : inputRef2.current,
                            katespadequizterm : true
                        });

                        const { email} = await getLeftInfo({uid : user?.uid})
                        let iiid = ranId().toString();
                        settt(email , inputRef2.current , iiid);
                    }
                    else{
                        addInfo({
                            name : inputRef.current,
                            email : inputRef2.current,
                            katespadequizterm : true
                        });

                        const { phoneNumberIsverified , phoneNumber} = await getLeftInfo({uid : user?.uid})
                        console.log(phoneNumberIsverified , phoneNumber)
                        let iiid = ranId().toString();
                        settt(inputRef2.current , phoneNumberIsverified || phoneNumber , iiid);
                    }

                    let collect = document.getElementById("collect-info2");
                    collect.style.opacity = 0;
                    collect.style.pointerEvents = "none";

                    window.showPage4();
                    }
                }

                    
            
                    >Enter</Button>

            <div style={{height : "3rem"}}/>

            </motion.div>
        </section>
    )
}

export default Index;
  