import Phaser from "phaser";
import React, { useEffect } from "react";
import Bootstrap from "./Bootstrap";
import Landing from "./landing";

const config = {
  type: Phaser.AUTO,
  parent: "phaser-container",
  transparent: true,
  width: window.innerWidth * window.devicePixelRatio,
  height: window.innerHeight * window.devicePixelRatio,
  scale: {
    mode: Phaser.Scale.ScaleModes.FIT,
  },
  physics: {
    default: "arcade",
    arcade: {
      gravity: { y: 0 },
      debug: true,
    },
  },

  scene: [Bootstrap , Landing],
};

const IoPhaser = React.forwardRef((props, ref) => {
  useEffect(() => {
    const phaserGame = new Phaser.Game(config);
    ref.current = phaserGame;
  }, []);

  return (
    <div
      id="phaser-container"
      style={{
        position: "absolute",
        top: "0",
        left: "50%",
        zIndex: "1",
        width: "100vw",
        height: "100svh",
        overflow: "hidden",
        transform: "translateX(-50%)",
      }}

      // check if out of the window

    ></div>
  );
});



export default IoPhaser;