import Phaser from "phaser";



class Bootstrap extends Phaser.Scene {
    constructor() {
        super("bootstrap");
    }

    init() {}

    preload() {
        // load spritesheets
        this.load.animation('aniData', '2d/ani.json');
        this.load.atlas("idea", "2d/idea.webp", "2d/idea.json");
        this.load.atlas("item1", "2d/item1.webp", "2d/item1.json");
        this.load.atlas("item2", "2d/item2.webp", "2d/item2.json");
        this.load.atlas("item3", "2d/item3.webp", "2d/item3.json");
        this.load.spritesheet("dragani" , "/2d/dragani.png" , {
            frameWidth : 150 , 
            frameHeight : 250
        });
    }

    create() {
        this.anims.create({
            key : "drag",
            frames : this.anims.generateFrameNumbers("dragani" , {start : 0 , end : 22}),
            frameRate : 15,
            repeat : -1
        });
        this.scene.start("landing");

        // body opacity to 1
        document.body.style.opacity = 1;
    }
}

export default Bootstrap;