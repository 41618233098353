export function Terms({ terms = false, setInfo = () => {} }) {
    return (
      <section
        style={{
          display: "flex",
        }}
        className="terms dontwantBold"
        id="superfanTerms"
      >
        <div
          onClick={() => {
            setInfo((prev) => {
              return {
                ...prev,
                terms: !prev.terms,
              };
            });
          }}
        >
          <div
            id="terms"
            style={{
              width: "0.8rem",
              height: "0.8rem",
              backgroundColor: "#fff",
              borderRadius: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
              marginRight: "1rem",
            }}
          >
            <div
              style={{
                width: "0.5rem",
                height: "0.5rem",
                backgroundColor: !terms ? "#fff" : "#a3a3a3",
                borderRadius: 0,
                border: "none",
              }}
            />
          </div>
        </div>
  
        <div>
          <p
            style={{
              color: "white",
              fontSize: "0.4rem !important",
              textAlign: "left",
              lineHeight: "1.2",
              letterSpacing: "0.1rem",
            }}
          >
            By submitting this form, you agree that kate spade may contact you using one of the communication channels you have provided to send you information about kate spade's products and services, promotions and other activities that may be of interest to you. For further details on how we manage your data, please see our 
            <span
              style={{
                textDecoration: "underline",
                fontSize: "0.4rem !important",
              }}
              onClick={() => {
                window.open("https://singapore.katespade.com/ks-privacy-policy", "_blank");
              }}
            >
              Privacy Policy.
            </span>
          </p>
        </div>
      </section>
    );
  }
  