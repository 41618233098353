import { useNavigate } from "react-router-dom"
import { useRef , forwardRef, useEffect} from "react"
import Lottie from 'react-lottie';
import loadingJson from "./loading.json";
import ReactGA from 'react-ga4';
import { useSuperfan } from "../..";
const Button = (props)=>{
    const { style , children ,id ,  ...rest } = props
    const { onClick = null , to = null , name } = rest
    const nav = useNavigate();
    const { recordEvent } = useSuperfan();
    return(
        <button
            className='toSameBorderRadius'
            style={{
                backgroundColor: "#131617",
                border: "transparent",
                width: "100%",
                fontWeight: "100",
                fontSize: "0.85rem",
                margin: "auto" ,
                padding : "0.9rem 0",
                letterSpacing: "0.017rem",
                fontFamily: "Poppins-Medium",
                ...style
            }}

            {...rest}

            onClick={()=>{
                recordEvent(name);
                if(onClick){
                    onClick()
                }
                else{
                    to && nav(to)
                }

            }}
        >
            {children}
        </button>

    )
}

const inputStyle = {
    boxSizing: "border-box",
    border : "transparent",
    color: "#fff",
    fontFamily: "Poppins-Medium",
    fontWeight: "100",
    backgroundColor: "#0E1111",
    letterSpacing: "0.1rem",
    fontSize: "0.9rem",
    paddingTop : "0.8rem",
    paddingBottom : "0.8rem",
}

const Input = ({
    placeholder = "set your placeholder",
    type = "text",
    onChange = (e)=>{},
})=>{
    const inputRef = useRef(null)
    return(
        <input
            ref={inputRef}
            type={type}
            placeholder={placeholder}
            style={{
                paddingLeft: "1rem",
                width: "100%",
                ...inputStyle
            }}
            onChange={onChange}
        />
    )

}

const InputMobile = ({
    placeholder = "12345678",
    type = "tel",
    valueRef,
    local = true
})=>{
    const countryCodeRef = useRef("+65");
    const mobileInputRef = useRef(null);
    return(
        <div
            style={{display : "flex" , justifyContent : "space-between"}}
        >
            <input style={{width : "20%" , textAlign : "center" ,...inputStyle}}
                defaultValue={"+65"}
                onChange={(e)=>{
                    countryCodeRef.current = e.target.value;
                    if(local){
                        localStorage.setItem("emailOrMobile",e.target.value + mobileInputRef.current);
                    }
                    valueRef.current = e.target.value + mobileInputRef.current;
                }}
            />
            <input style={{width : "73%" , paddingLeft : "1rem" ,...inputStyle}}
                type={type}
                placeholder={placeholder}

                onChange={(e)=>{
                    if(local){
                        localStorage.setItem("emailOrMobile",countryCodeRef.current + e.target.value);
                    }
                    mobileInputRef.current = e.target.value;

                    valueRef.current = countryCodeRef.current + e.target.value;
                }}
            />
        </div>
    )
}

const InputEmail = ({valueRef})=>{
    return (
        <Input
          placeholder = {"john@example.com"}
          type = {"email"}
          onChange = {(e)=>{
            localStorage.setItem("emailOrMobile",e.target.value);
            valueRef.current = e.target.value;
          }}
        />
    )
}

const OtpBox = forwardRef((props,ref) => {
    
    const { style , children , ...rest } = props
    const { index, value, onChange, onKeyDown } = rest

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: loadingJson,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };

    useEffect(()=>{
        let lottiePaths = document.querySelectorAll(".lottieContainer path");
        for(let i = 0; i < lottiePaths.length; i++){
            lottiePaths[i].style.stroke = "#00bf63";
        }
    },[])
  

    return(
    <div
        style={{
            width : "15%",
            height : "13svw",
            position : "relative",
        }}
    >
        <input type='number'
            className={"otp" + " " + "otp" + index} 
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            style={{
                backgroundColor: "#141617",
                border : "1.3px solid #fff",
                width : "100%",
                height : "13svw",
                borderRadius : "0.3rem",
                color : "#fff",
                fontFamily: "Poppins-Medium",
                fontWeight: "700",
                textAlign : "center",
                fontSize : "1.2rem",
            }}

            maxLength="1"
            ref={ref}
        />

        <div
            className="lottieContainer"
            style={{
                width : "2.5rem",
                height : "2.5rem",
                position : "absolute",
                top : "50%",
                left : "50%",
                transform : "translate(-50%,-50%)",
                display : "flex",
                justifyContent : "center",
                alignItems : "center",
                opacity : 0,
                pointerEvents : "none"
            }}
        >
            <Lottie 
                options={defaultOptions}
                width={"100%"}
                height={"100%"}
            />
        </div>
    </div>
);
});

export const AnimateBox = ()=>{
    return (
        <div className="loader loader--style8" title="7"
            style={{
                height : "1.2rem"
            }}
        >
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                height="100%" viewBox="0 0 24 30" style={{enableBackground: 'new 0 0 50 50'}} xmlSpace="preserve">
            <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
            </rect>
            <rect x="8" y="10" width="4" height="10" fill="#333" opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
            </rect>
            <rect x="16" y="10" width="4" height="10" fill="#333" opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
            </rect>
            </svg>
        </div>
    )
}

export {
    Button,
    OtpBox,
    Input,
    InputMobile,
    InputEmail
}