import {motion} from "framer-motion";

const Index = ({
    play,
    setPlay,
    imageWidth,
    y,
    id ,
    imgSrc,
    backgroundColor,
    children,
    invert = false
})=>{
    return (
        <g
            transform={`translate(0,${y})`}
            id={id}
            style={{transition : "0.2s"}}
        >
            <defs>
                <clipPath id = {"clip" + id}>
                    <motion.circle cx={window.innerWidth * 0.5} cy={window.innerHeight * 0.5}
                        initial={{r: window.innerHeight * 0.114}}
                        animate={
                            play ? {r: window.innerHeight * 0.114 , 
                            transition : {
                                delay : 0.5
                            }} : {r: window.innerHeight}
                            
                        }
                    />
                </clipPath>
            </defs>

            <rect
                x="0"
                y={-y}
                width={window.innerHeight * 5}
                height={window.innerHeight * 5}
                fill={backgroundColor}
                clipPath={`url(#clip${id})`}
            >
            </rect>

           
            {children}
        </g>
    )
}

export default Index;