
import {motion} from "framer-motion";
import { useEffect,useState } from "react";
import Gcontainer from "./gcontainer";
import { delayTime } from "../../core"
const cloudImages = [
    '/2d/cloud1.png',
    '/2d/cloud2.png',
    '/2d/cloud3.png',
  ];
  
const generateList = (quantity = 6)=>{
    let _ = {};
    for(let i = 0; i < quantity ; i ++){
        let _id = parseInt(Date.now() * Math.random());
        _[_id] = {
            x : window.innerWidth * Math.random(),
            y : window.innerHeight * Math.random() - window.innerHeight * 0.3,
            image: cloudImages[Math.floor(Math.random() * cloudImages.length)], 
            speedX : Math.round(1 * Math.random() + 0.5)
        }
    }

    return _;
}

let list = generateList();


const Index = ()=>{
    const [play , setPlay] = useState(false);

    useEffect(()=>{
        const ani = ()=>{
            Object.keys(list).map((itemID , index)=>{
                let item = document.getElementById(itemID);
                if(item){
                    let x = item.getAttribute("x");
                    x = parseInt(x);
                    x += list[itemID]?.speedX || 1;
                    if(x > window.innerWidth){
                        x = -300
                    }
                    item.setAttribute("x" , x)    
                }
            })
            requestAnimationFrame(ani);
        }

        window.setPlay3 = ()=>{
            setPlay((prev)=>{
                if(!prev){
                    document.getElementById("ani1").style.opacity = 0;
                    document.getElementById("ani2").style.opacity = 0;    
                }
                else{
                    setTimeout(()=>{
                        document.getElementById("ani1").style.opacity = 1;
                        document.getElementById("ani2").style.opacity = 1;    
                    },delayTime);
                }
                return !prev;
            });
        }

        ani();
    },[])


    return (
        <Gcontainer
            play = {!play}
            setPlay = {setPlay}
            imageWidth = {window.innerWidth * 0.4}
            y = {window.innerHeight * 0.31}
            id = "ani3"
            imgSrc = "/3.png"
            backgroundColor={"#5cb780"}
        >
            {
                Object.keys(list).map((item , index)=>{
                    return (
                        <image 
                        clipPath="url(#clipani3)"
                        height="36" width="117" href={list[item]?.image} key={item} x={list[item].x} y ={list[item].y} id={item}/>
                    )
                })
            }

            <image
                href="/2d/logo_green.png"
                width={window.innerHeight * 0.09 * 1.72}
                height={window.innerHeight * 0.09}
                clipPath="url(#clipani3)"
                x={window.innerWidth * 0.5 - window.innerHeight * 0.09 * 1.72 / 2 }
                y={window.innerHeight * 0.0822 - window.innerHeight * 0.09 / 2 - window.innerHeight * 0.31}
            />

        </Gcontainer>
    )
}

export default Index;